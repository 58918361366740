<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92.5 34.36">
  <path
    d="m83.76 20-7.42-5.1 7.42-5.1a.94.94 0 0 0 .25-1.32l-3.02-4.39a.95.95 0 0 0-1.32-.25l-6.12 4.2V.95A.94.94 0 0 0 72.6 0h-5.27a.94.94 0 0 0-.95.95v7.09l-6.12-4.2a.95.95 0 0 0-1.32.25l-3.02 4.39a.95.95 0 0 0 .25 1.32l7.42 5.1-7.42 5.1a.94.94 0 0 0-.25 1.32l3.02 4.39c.29.44.89.55 1.32.25l9.7-6.66 9.7 6.66a.94.94 0 0 0 1.32-.25L84 21.32a.94.94 0 0 0-.25-1.32Z"
    style="fill: #fdce0c"
  />
  <path
    d="M24.48 34.08h-3.96c-.44 0-.8-.1-1.08-.31s-.48-.48-.62-.81l-1.67-4.71H7.33l-1.67 4.71c-.11.28-.31.54-.6.77s-.64.35-1.07.35H0L9.64 9.41h5.22l9.62 24.67Zm-8.57-9.37-2.67-7.55a24.44 24.44 0 0 1-1-3.12 32.58 32.58 0 0 1-1 3.16l-2.65 7.51h7.33-.01Zm23-6.26c0 .33-.06.65-.17.96s-.26.56-.44.76l-7.87 10.37h8.26v3.54H25v-1.94c0-.19.05-.44.16-.73s.26-.57.45-.83l7.94-10.49h-8.06v-3.54H38.9v1.9Zm8.98 15.9c-.79 0-1.5-.12-2.11-.35a4.1 4.1 0 0 1-2.5-2.56 6.08 6.08 0 0 1-.32-2.07v-9.45h-1.65c-.25 0-.46-.08-.64-.24s-.26-.4-.26-.71v-1.84l2.81-.51.95-4.54c.12-.51.47-.76 1.04-.76h2.45v5.34h4.52v3.26h-4.52v9.15c0 .49.12.87.35 1.16.23.28.56.43.99.43a1.93 1.93 0 0 0 .99-.25l.31-.17c.09-.05.2-.08.3-.08.14 0 .25.03.34.09s.18.17.27.32l1.41 2.24c-.65.51-1.38.9-2.19 1.16a8.3 8.3 0 0 1-2.53.39h-.01Zm11.18-14.91c.56-1 1.2-1.79 1.92-2.36a3.94 3.94 0 0 1 2.55-.87c.79 0 1.44.19 1.94.56l-.31 3.47c-.06.23-.14.38-.26.47s-.28.13-.48.13c-.18 0-.44-.03-.78-.08s-.66-.08-.95-.08c-.43 0-.81.06-1.15.19-.33.12-.63.3-.89.53-.26.23-.5.5-.71.83s-.41.7-.59 1.12v10.73h-4.69V16.55h2.77c.48 0 .8.08.99.25.18.17.31.46.39.88l.25 1.75Zm14.07-7.87a3.09 3.09 0 0 1-.96 2.18c-.29.28-.63.5-1.02.67s-.81.24-1.25.24a3 3 0 0 1-2.19-.91 3 3 0 0 1-.68-3.39c.16-.38.39-.71.68-.99s.61-.5.99-.67.77-.24 1.2-.24a3.24 3.24 0 0 1 2.97 1.9c.18.38.26.78.26 1.21Zm-.82 4.98v17.53h-4.69V16.55h4.69ZM92.5 34.08H88c-.34 0-.62-.08-.83-.25s-.38-.37-.5-.59l-3.45-5.76c-.1.39-.25.73-.44 1.02l-2.96 4.74c-.14.21-.3.41-.5.59s-.45.26-.76.26h-4.18l5.95-9.04-5.71-8.48h4.5c.34 0 .59.05.75.14s.3.24.43.44l3.43 5.56a5.37 5.37 0 0 1 .54-1.18l2.64-4.3c.27-.44.62-.66 1.05-.66h4.3l-5.71 8.28 5.95 9.25v-.02Z"
    style="fill: #2b5614"
  />
</svg>
